<template>
  <el-main>
    <el-button style="margin-bottom:10px;" type="primary" size="small" @click="toAdd">导入商品</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品信息：">
        <el-input size="small" v-model="keyword" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="searchFn">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :cell-style="() => 'text-align:center'" :data="allStatusGoods" :header-cell-style="{ 'background-color': '#F8F9FA', 'text-align': 'center' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="wx_goods_id" label="商品ID" width="100">
        <template v-slot="{ row }">
          {{ row.sku_id ? row.sku_id : row.goods_id }}
        </template>
      </el-table-column>
      <el-table-column label="商品信息">
        <template v-slot="{ row }">
          <div class="liveGoodsInfo">
            <img :src="row.sku_id ? row.specGoods.picture : row.goods.goods_pictures[0]" alt="" />
            <div class="infoBox">
              <div class="name">名称：{{ row.goods.goods_name }}</div>
              <div v-if="row.sku_id">规格：{{ row.specGoods.title }}</div>
              <div>库存：{{ row.sku_id ? row.specGoods.stock : row.goods.goods_stock }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="入库价格" width="200">
        <template v-slot="{ row }">
          <div v-if="!row.isUpdatePrice">{{ row.price }}元</div>
          <div v-else>
            <el-input id="updatePrice" style="width: 100px" size="mini" v-model="row.updatePrice"></el-input>
            元
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-button type="text" v-if="!row.isUpdatePrice" @click.stop="updatePriceFn(row)">编辑图册价格</el-button>
          <el-button type="text" v-else @click.stop="cancelUpdate">取消</el-button>
          <el-button type="text" @click="deleteGoods(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="pageNums" @updatePageNum="updateData"></Paging>
    <!-- 导入商品 -->
    <el-dialog title="选择商品" :visible.sync="chooseGoddsFlag" width="1050px">
      <div class="chooseGoodsBox">
        <div class="top searchBox">
          <div class="searchItem">
            <div class="title">商品信息：</div>
            <div class="searchContent">
              <el-input size="small" v-model="keywordLog" placeholder="请输入商品名称"></el-input>
            </div>
          </div>
          <div class="searchItem">
            <div class="title">商品分类：</div>
            <div class="searchContent">
              <el-select v-model="chooseClass" size="small" placeholder="请选择">
                <el-option v-for="item in goodsClassList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <el-button type="primary" size="small" @click="searchFnLog">搜索</el-button>
        </div>
        <div class="bot">
          <div class="showList">
            <div class="listHead itemRow">
              <div>商品信息</div>
              <div>单价</div>
              <div>商品入册价格</div>
              <div>操作</div>
            </div>
            <div v-for="(item, index) in storeAllGoods" :key="index">
              <div class="listItem itemRow" @click="item.is_showSpec = !item.is_showSpec">
                <div class="goodsInfo">
                  <i :class="['el-icon-caret-right', item.is_showSpec ? 'ro90' : '']" v-if="item.is_spec"></i>
                  <img :style="{ margin: item.is_spec ? ' 0 10px' : '0 10px 0 34px' }" :src="item.goods_picture" alt="" />
                  <div>
                    <p>{{ item.goods_name }}</p>
                    <p v-if="!item.is_spec">库存：{{ item.goods_stock != -1 ? item.goods_stock : '无限库存' }}</p>
                  </div>
                </div>
                <div class="price">{{ item.goods_price }}</div>
                <div class="livePrice" v-if="item.is_spec"></div>
                <div class="livePrice" v-else>
                  <el-input v-model="item.live_price" size="small"></el-input>
                  元
                </div>
                <div class="status" v-if="item.is_spec"></div>
                <div class="status cupo" @click="surePushLive(item, 1)" :style="{ color: '#409eff' }" v-else>加入图册</div>
              </div>
              <div class="manySize" :style="{ height: item.is_showSpec ? item.goods_spec.length * 70 + 'px' : '0px' }" v-if="item.is_spec">
                <div class="itemRow" v-for="(item1, index1) in item.goods_spec" :key="index1">
                  <div class="goodsInfo">
                    <img :src="item1.picture" alt="" />
                    <div>
                      <p>{{ item1.title }}</p>
                      <p>库存：{{ item1.stock != -1 ? item1.stock : '无限库存' }}</p>
                    </div>
                  </div>
                  <div class="price">{{ item1.price }}</div>
                  <div class="livePrice">
                    <el-input v-model="item1.live_price" size="small"></el-input>
                    元
                  </div>
                  <div class="status cupo" @click="surePushLive(item1, 2, item)" :style="{ color: '#409eff' }">加入图册</div>
                </div>
              </div>
            </div>
          </div>
          <Paging :total="totalLog" :page="pageLog" :pageNum="pageNumsLog" @updatePageNum="updateDataLog"></Paging>
        </div>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      page: 1,
      pageNums: 10,
      total: 0,
      allStatusGoods: [],
      keyword: '',
      chooseGoddsFlag: false,
      storeAllGoods: [],
      keywordLog: '',
      pageLog: 1,
      pageNumsLog: 10,
      totalLog: 0,
      chooseClass: 0,
      goodsClassList: [],
      // 此时被修改的价格的那一项
      nowUpdateItem: null,
    };
  },
  mounted() {
    document.addEventListener('click', this.documentCFn);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentCFn);
  },
  created() {
    this.getLiveList();
  },
  methods: {
    async toAdd() {
      let a = await this.getStoreAllClass();
      let b = await this.getStoreAllGoods();
      this.chooseGoddsFlag = true;
    },
    documentCFn($evevt) {
      if ($evevt.target.id !== 'updatePrice' && this.nowUpdateItem) {
        this.updatePriceSuccess(this.nowUpdateItem);
      }
    },
    // 获取已参与的商品列表
    getLiveList() {
      let that = this;
      this.$axios
        .post(this.$api.user.get_product_album_list, {
          page: this.page,
          rows: this.pageNums,
          name: this.keyword,
        })
        .then(res => {
          if (res.code == 0) {
            that.allStatusGoods = res.result.list;
            that.allStatusGoods.map(item => {
              that.$set(item, 'updatePrice', item.price);
              that.$set(item, 'isUpdatePrice', false);
              if (item.sku_id) {
                let specGoods = item.goods.goods_spec.find(item1 => item1.id === item.sku_id);
                this.$set(item, 'specGoods', specGoods);
              }
            });
            that.total = res.result.total;
          }
        });
    },
    // 修改分页数据
    updateData(val, status) {
      if (status == 0) {
        this.pageNums = val;
      } else {
        this.page = val;
      }
      // 重新获取数据
      this.getLiveList();
    },
    updateDataLog(val, status) {
      if (status == 0) {
        this.pageNumsLog = val;
      } else {
        this.pageLog = val;
      }
      // 重新获取数据
      this.getStoreAllGoods();
    },
    // 点击进行搜索
    searchFn() {
      this.page = 1;
      this.getLiveList();
    },
    // 清空搜索
    clearSearch() {
      this.page = 1;
      this.keyword = '';
      this.getLiveList();
    },
    // 获取站点所有商品列表
    getStoreAllGoods() {
      let that = this;
      this.$axios
        .post(this.$api.push.liveRoom.getGoodList, {
          page: this.pageLog,
          rows: this.pageNumsLog,
          goods_name: this.keywordLog,
          classify_id: !this.chooseClass ? null : this.chooseClass,
          is_putaway: 2,
          status: 1,
        })
        .then(res => {
          if (res.code === 0) {
            that.totalLog = res.result.total_number;
            that.storeAllGoods = res.result.list;
            that.storeAllGoods.map(item => {
              that.$set(item, 'is_showSpec', true);
              if (!item.is_in) {
                item.live_price = item.goods_price;
              }
              if (!item.is_spec) {
                that.$set(item, 'coverImgUrl', '');
                that.$set(item, 'cover_img', '');
              } else {
                item.goods_spec.map(item1 => {
                  if (!item1.is_in) {
                    item1.live_price = item1.price;
                  }
                  that.$set(item1, 'coverImgUrl', '');
                  that.$set(item1, 'cover_img', '');
                });
              }
            });
          }
        });
    },
    // 获取站点商品分类列表
    getStoreAllClass() {
      let that = this;
      this.$axios.post(this.$api.goods.goodsClass).then(res => {
        if (res.code === 0) {
          that.goodsClassList = res.result;
          that.goodsClassList.unshift({ id: 0, name: '全部' });
        }
      });
    },
    searchFnLog() {
      this.pageLog = 1;
      this.getStoreAllGoods();
    },
    // 确认将选中的商品推送到直播商品库
    surePushLive(row, status, pItem) {
      let that = this;
      let obj = {
        id: row.id,
        price: row.live_price,
      };
      if (status == 2) {
        obj.id = pItem.id;
        obj.sku_id = row.id;
      }
      this.$axios.post(this.$api.user.add_product_album, obj).then(res => {
        if (res.code == 0) {
          that.$message.success('添加成功');
          that.getLiveList();
          that.chooseGoddsFlag = false;
        } else {
          that.$message.error(res.msg);
        }
      });
    },
    // 点击修改价格
    updatePriceFn(row) {
      this.nowUpdateItem = row;
      row.isUpdatePrice = true;
    },
    cancelUpdate() {
      this.nowUpdateItem.isUpdatePrice = false;
      this.nowUpdateItem = null;
    },
    // 成功修改价格
    updatePriceSuccess(row) {
      let that = this;
      let obj = {
        product_id: row.wx_goods_id,
        price: row.updatePrice,
      };
      if (obj.sku_id) {
        obj.sku_id = row.sku_id;
      }

      obj.id = row.goods_id;

      this.$axios.post(this.$api.user.update_product_album, obj).then(res => {
        if (res.code === 0) {
          row.isUpdatePrice = false;
          that.nowUpdateItem = null;
          row.price = row.updatePrice;
          that.$message.success('修改成功');
        } else {
          that.$message.error(res.msg);
        }
      });
    },
    // 删除该商品
    deleteGoods(row) {
      this.$confirm('此操作将该商品从产品图册中删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.user.delete_product_album, {
            product_id: row.wx_goods_id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.getLiveList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .el-table {
    height: 100%;
    /deep/ .el-table__body-wrapper {
      height: calc(100% - 48px);
      overflow-y: auto;
    }
  }
  .liveGoodsInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
    }
    .infoBox {
      text-align: left;
      margin-left: 10px;
    }
  }
  .chooseGoodsBox {
    .top{
      display: flex;
      margin-bottom: 15px;
      .searchItem{
        display: flex;
        align-items: center;
        margin-right: 15px;
      }
    }
    .bot {
      .showList {
        width: 100%;
        .listHead {
          width: 100%;
          display: flex;
          line-height: 48px;
          background-color: #f8f9fa;
        }
        .itemRow {
          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          & > div:first-of-type {
            width: 300px;
            flex-shrink: 0;
            padding: 0 10px;
            i {
              font-size: 24px;
              transition: all 0.3s;
            }
            .ro90 {
              transform: rotate(90deg);
            }
            img {
              width: 40px;
              height: 40px;
            }
            & > div {
              height: 100%;
              flex: 1;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 14px 0;
              p {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          & > div:nth-of-type(2) {
            width: 100px;
            flex-shrink: 0;
          }
          & > div:nth-of-type(3) {
            img {
              width: 60px;
              height: 60px;
            }
            font-size: 12px;
            width: 300px;
            flex-shrink: 0;
          }
          & > div:nth-of-type(4) {
            width: 300px;
            flex-shrink: 0;
            .el-input {
              width: 100px;
            }
          }
          & > div:nth-of-type(5) {
            flex: 1;
            flex-shrink: 0;
          }
        }
        .cupo {
          cursor: pointer;
        }
        .listItem {
          display: flex;
          height: 70px;
          cursor: pointer;
          &:hover {
            background-color: #f5f7fa;
          }

          .el-input {
            width: 100px;
            margin-right: 5px;
          }
        }
        .manySize {
          transition: all 0.3s;
          overflow: hidden;
          .el-input {
            width: 100px;
          }
          .itemRow {
            display: flex;
            height: 70px;
            &:hover {
              background-color: #f5f7fa;
            }
            .el-input {
              margin-right: 5px;
            }
          }
          .goodsInfo {
            padding: 0 10px 0 75px !important;
            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .priceBox {
    display: flex;
    align-items: center;
  }
}
</style>
